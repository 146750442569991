
import { defineComponent, PropType } from 'vue'

import { useField } from 'vee-validate'

export default defineComponent({
  name: 'Checkbox',
  emits: ['update:checked'],
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    checked: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    id: {
      type: String as PropType<string>,
      default: ''
    },
    name: {
      type: String as PropType<string>,
      // required: true
    },
  },
  setup(props) {
    // const { checked, handleChange } = useField(props.name, undefined, {
    //   type: 'checkbox',
    //   checkedValue: props.value
    // })

    // handleChange(props.value)

    const test = () => {
      // $emit('update:modelValue', !modelValue)
      console.log('click')
    }

    return {
      test,
      // checked,
      // handleChange
    }
  }
})
