
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import HomeContent from '@/components/HomeContent.vue';
import Card from '@/components/Card.vue';
import Cards from '@/components/Cards.vue';

export default defineComponent({
  components: {
    HomeContent,
    Card,
    Cards
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const homeComponent = computed(() => {
      if (route.query.card)
        return 'Card';
      else if (store.getters.isUserLoggedIn)
        return 'Cards';
      else
        return 'HomeContent';
    });

    return {
      homeComponent,
    }
  },
})
