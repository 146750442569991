
import { computed, defineComponent, PropType, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import { TCardData, TCardDataField } from '~/types/common';

export default defineComponent({
  name: 'CardRead',
  emits: ['editCardData'],
  props: {
    cardData: {
      type: Object as PropType<TCardData>,
      required: true
    },
    cardKey: {
      type: String as PropType<string>,
    },
    readOnly: {
      type: Boolean as PropType<boolean | void>,
    }
  },
  components: {
    QrcodeVue
  },
  setup (props , { emit }) {
    const store = useStore();

    const frontVisibilityIs = ref(true)
    const cardHoveredStateIs = ref(false)
    const qrCodeString = ref('')

    const visibleField = (field: TCardDataField, key: string) => {
      return key !== 'firstName' 
            && key !== 'lastName'
            && key !== 'company'
    }

    const setQrCode = (cardKey: string) => {
      if(!cardKey) 
        return console.error('QR could not generate because card key is missing.');

      qrCodeString.value = `${window.location.protocol + window.location.host}?card=${cardKey}`;
    }

    const editCard = (e: Event) => {
      e.stopPropagation();
      // console.log('emit edit card')
      emit('editCardData')
    }

    onMounted(() => {
      if (props.cardKey) setQrCode(props.cardKey);
    })

    // watch(() => frontVisibilityIs.value, (newValue, oldValue) => {
    //   if (!newValue 
    //       && !store.state.shellStore.promptedForInstallation 
    //       && store.state.shellStore.deferredPrompt) {
    //     setTimeout(()=> {
    //       store.commit('SET_INSTALLION_PROMPT_TO_TRUE')
    //       store.commit('SET_MODAL_VISIBILITY', true)
    //     }, 3000)
    //   }
    // })

    return {
      cardHoveredStateIs,
      editCard,
      fullName: computed(() => `${props.cardData?.firstName || ''} ${props.cardData?.lastName || ''}`),
      company: computed(() => props.cardData?.company ? props.cardData?.company : null),
      visibleField,
      qrCodeString,
      frontVisibilityIs, 
      getInstallationPrompt: computed(() => store.state.promptedForInstallation),
    }
  }
})
