
import { computed, defineComponent, reactive, ref, PropType, onBeforeMount } from 'vue'
import { useStore } from 'vuex'

import { TCardData } from '~/types/common'
import Checkbox from '@/components/Checkbox.vue'

type TField = {
  key: "firstName" | "lastName" | "email" | "company" | "address" | "phone" | "website",
  icon: string
  label: string,
  name: "given-name" | "family-name" | "email" | "organization" | "street-address" | "phone" | "url",
  type: "email" | "tel" | "text" | "url",
  placeholder: string,
  required: boolean,
  // value: Ref<string>,
  // errorMessage: ComputedRef<string | undefined>
  errorMessage: string | undefined,
  value: string,
  show: boolean
}

export default defineComponent({
  name: 'CreateCard',
  components: {
    Checkbox,
  },
  props: {
    creatingCard: {
      type: Boolean as PropType<boolean>,
      required: false
    },
    cardData: {
      type: Object as PropType<TCardData>,
      required: false
    }
  },
  emits: ['cardDataSubmittedToServer', 'cancelCardDataUpdate'],
  setup(props, { emit }) {
    const store = useStore()
    const step = ref(1)
    const submitted = ref(false)
    const selectedFields = reactive(['']);

    let fields: TField[] = [
      {
        label: 'First Name',
        icon: 'person',
        // value: yup.string().required().min(1),
        key: 'firstName',
        name: "given-name",
        type: 'text',
        placeholder: 'My first name is...',
        required: false,
        errorMessage: undefined,
        value: '',
        show: false,
      },
      {
        label: 'Last Name',
        icon: 'person',
        key: 'lastName',
        name: "family-name",
        type: 'text',
        placeholder: 'My last name is...',
        required: false,
        errorMessage: undefined,
        value: '',
        show: false,
      },
      {
        label: 'Phone',
        icon: 'phone',
        key: 'phone',
        name: 'phone',
        type: 'tel',
        placeholder: 'My phone number is...',
        required: false,
        errorMessage: undefined,
        value: '',
        show: false,
      },
      {
        label: 'Email',
        icon: 'mail',
        key: 'email',
        name: 'email',
        type: 'email',
        placeholder: 'My email is...',
        required: false,
        errorMessage: undefined,
        value: '',
        show: false,
      },
      {
        label: 'Company',
        icon: 'work',
        key: 'company',
        name: 'organization',
        type: 'text',
        placeholder: 'My company name is...',
        required: false,
        errorMessage: undefined,
        value: '',
        show: false,
      },
      {
        label: 'Website',
        icon: 'computer',
        key: 'website',
        name: 'url',
        type: 'url',
        placeholder: 'My website url is...',
        required: false,
        errorMessage: undefined,
        value: '',
        show: false,
      },
      // {
      //   label: 'Address',
      //   // value: 'Unit 4604/371 Little Lonsdale St',
      //   icon: 'home',
      //   value: '',
      //   key: 'address',
      //   name: 'street-address',
      //   type: 'text',
      //   placeholder: 'My address',
      //   required: false,
      //   errorMessage: undefined,
      // }
    ]

    onBeforeMount(() => {
      // if not creating card or no data available then fill fields in component
      if (props.creatingCard || !props.cardData) 
        return

      const fieldsCopy = [...fields];
      let propData: keyof typeof props.cardData;
      // @ts-ignore: propData can only be of type string or any
      for (propData in props.cardData) {
        let fieldRef = fieldsCopy.find(field => field.key === propData);
        if (fieldRef == undefined) 
          return;
        // @ts-ignore: show doesn't exist on type 'never'
        fieldRef.value = props.cardData[propData];
        // @ts-ignore: show doesn't exist on type 'never'
        fieldRef.show = props.cardData[propData] ? true : false;
      }
      fields = fieldsCopy;
    })

    const submitForm = async () => {
      submitted.value = true;

      let deliverable = {} as TCardData;

      fields
        .filter(field => field.show)
        .forEach(field => {
          deliverable = {
            ...deliverable,
            [field.key]: field.value
          }
        })

      try {
        await store.dispatch('setCardData', deliverable);
        emit('cardDataSubmittedToServer')
      } catch (err) {
        submitted.value = false;

        console.error(err);
      }
    }

    const schema = {
      show: (value: any) => {
        if (value && value.length) return true
        return "Must choose field"
      }
    }

    return {
      fields,
      getCardData: computed(() => store.getters.getCardData),
      getCardIsFilledState: computed(() => store.getters.getCardIsFilledState),
      schema,
      selectedFields,
      step,
      submitted,
      submitForm,
    }
  }
})
