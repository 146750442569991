
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

import Card from '@/components/Card/Card.vue'

export default defineComponent({
  name: 'Cards-view',
  components: {
    Card,
  },
  setup() { 
    const store = useStore();

    return {
      getCardData: computed(() => store.getters.getCardData)
    }
  }
})
