
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import gsap from 'gsap';

import CardCreateUpdate from './CardCreateUpdate.vue'
import CardRead from './CardRead.vue'
// import CardDelete from '@/components/CardDelete.vue'
import Loader from '@/components/Loader.vue'

export default defineComponent({
  name: 'Card',
  components: {
    CardCreateUpdate,
    CardRead,
    Loader,
  },
  setup() {
    const store = useStore();
    const creatingCard = ref(false)
    
    const card = ref<HTMLDivElement | null>(null);
    const cardContentEl = ref<HTMLDivElement | null>(null);
    const cardContentHeight = ref<number | null>(null);

    const cardContentSelector = '.card-content'
    // const cardContentSelector = '[data-card-content]'

    const tl = gsap.timeline();
    // tl.timeScale( 0.2 ); 

    let cardState = ref('CardRead');

    const onBeforeEnter = (el: any) => {     
      cardContentEl.value = el.querySelector(cardContentSelector);
      cardContentHeight.value = el.querySelector(cardContentSelector).offsetHeight;

      tl.set(cardContentEl.value, { autoAlpha: 0 })
      
      if (cardState.value === 'CardCreateUpdate') {
        tl.set(el, { maxHeight: `180px` })
      }
    }

    const onEnter = (el: any, done: any) => {
      cardContentEl.value = el.querySelector(cardContentSelector);
      cardContentHeight.value = el.querySelector(cardContentSelector).offsetHeight;

      // actually animating CardRead because cardState change triggered enter animation
      // so only animate content
      if (cardState.value === 'CardRead') {
        tl.to(cardContentEl.value, { autoAlpha: 1, duration: 0.3, onComplete: done})
      }

      // actually animating CardCreateUpdate because cardState change triggered enter animation
      if (cardState.value === 'CardCreateUpdate') {
        tl.set(el, { maxHeight: `180px` })
        tl.to(el, { maxHeight: `${cardContentHeight.value}px`, duration: 0.3, delay: 0.2 })
        tl.to(cardContentEl.value, { autoAlpha: 1, duration: 0.3, onComplete: done })
        tl.set(el, { maxHeight: `none` })
      }
    }
   
    const onLeave = (el: any, done: any)=>  {
      cardContentEl.value = el.querySelector(cardContentSelector)
      cardContentHeight.value = cardContentEl.value!.offsetHeight;

      // actually animating CardRead because cardState change triggered leave animation
      // so only animate content
      if (cardState.value === 'CardCreateUpdate') {
        tl.to(cardContentEl.value, { autoAlpha: 0, duration: 0.3, onComplete: done })
      }
     
      // actually animating CardCreateUpdate because cardState change triggered leave animation
      if (cardState.value === 'CardRead') {
        tl.set(el, { maxHeight: `${cardContentHeight.value}px` })
        tl.to(cardContentEl.value, { autoAlpha: 0, duration: 0.3 })
        tl.to(el, { maxHeight: `180px`, duration: 0.3, delay: 0.2 })
        tl.to(el, { maxHeight: `none`, duration: 0, onComplete: done })
      }
    }

    return {
      cardState,
      creatingCard,
      getCardData: computed(() => store.getters.getCardData),
      getCardKey: computed(() => store.getters.getCardKey),
      getCardIsFilledState: computed(() => store.getters.getCardIsFilledState),
      loadingData: computed(() => store.state.loadingCardData),
      onBeforeEnter,
      onEnter,
      onLeave,
      card,
    }
  }
})
