
import { ref, computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

import { TReview } from '~/types/common'
import Logo from '@/components/Logo.vue'
import Slider from '@/components/Slider.vue'
// import Review from '@/components/Review.vue'

type TUsp = {
  icon: string,
  title: string,
  body: string
}

export default defineComponent({
  components: {
    Logo,
    // Review,
    Slider
  },
  setup() {
    const usps: TUsp[] = [
      // {
      //   icon: 'wallet',
      //   title: 'Add to Wallet',
      //   body: `Add your card to your phone's Wallet. So you'll always have your card on hand, ready to share. No more forgetting your cards or them wearing down!`
      // },
      {
        icon: 'wallet',
        title: 'Always having your card on you',
        body: `Have your card digitally available so that you'll always have it on hand and ready to share your details. So you don't have to worry about forgetting, misplacing, damaging your – and your connection's – cards.`
        // 'Add to wallet' addition
        // 'Add your card to your phone's 'Wallet' for easy access.'
      },
      {
        icon: 'contacts',
        // title: 'Adds to Contacts app',
        // body: `Your details are saved in the scanners Contacts app. The one they've selected and know there way around.`
        title: 'One-sided sharing',
        body: `Your connection doesn't need to have Scrolldex in order for them to save your details! Your details are saved in the connection's phone (iPhone & Android: Contacts app). The app they know their way around.`
      },
      {
        icon: 'nature',
        title: 'Reducing waste',
        body: `If you always have your phone on you, why use something physical you have to throw out eventually? Have it digitally available and reduce your wallets bloating and paper/plastic waste.`
        // body: `No trees were hurt while making this app. A couple are planted with every member that signs up though!`
      },
      {
        icon: 'published_with_changes',
        title: 'Still being reachable after your details update',
        body: `When we switch jobs we're likely to get a new professional phone, email address, etc. So, updating these details might mean that our old contact aren't able to reach us anymore. With Scrolldex this won't be a problem, because your details are automatically updated for fellow Scrolldexers. And even for non-Scrolldex users, there is a link in their Contacts app notes under your name so that they can still reach out to you!`
        // body: `No trees were hurt while making this app. A couple are planted with every member that signs up though!`
      },
    ];

    type TExtra = {
      title: string,
      body: string,
    }

    const extras: TExtra[] = [
      {
        title: 'Custom branding',
        body: `already have a designed paper business card you would like to use? Upload it to share it with fellow Scrolldexer's.`
      },
      {
        title: 'Create multiple cards',
        body: `would you like a different card for different roles? Maybe a different one to share with friends? Not a problem! Create different cards for different occasions.`
      },
      {
        title: 'Add a link for email signature',
        body: `Instead of your email correspondants having to manually take over your details from your email signature, just have them click the link so that they can add them all with one click!`
      },
      {
        title: 'Desktop access',
        body: `Not enough screen size to see all your contacts on your phone? Log in on any device when you'd like to see your contacts, edit details, or add new cards.`
      },
      // {
      //   title: 'Changing your details',
      //   body: `When we switch jobs we're likely to get a new professional phone, email address, etc. So, updating these details might mean that our old contact aren't able to reach us anymore. With Scrolldex this won't be a problem, because your details are automatically updated for fellow Scrolldexers. And even for non-Scrolldex users, there is a link in their Contacts app notes under your name so that they can still reach out to you!`
      // },
    ]

    const reviews: TReview[] = [
      {
        imgSrc: 'https://bulma.io/images/placeholders/128x128.png',
        author: 'Tamaki Ryushi',
        jobTitle: 'Account Manager',
        quote: 'Never going back to physical cards ever again!'
      },
      {
        imgSrc: 'https://bulma.io/images/placeholders/128x128.png',
        author: 'Gibby Radki',
        jobTitle: 'CEO of Addy',
        quote: 'So easy to use!'
      },
      {
        imgSrc: 'https://bulma.io/images/placeholders/128x128.png',
        author: 'Lizzie Rose',
        jobTitle: 'online marketeer',
        quote: 'An tiny investment you don\'t have to think twice about!'
      },
    ]

    return {
      extras,
      reviews,
      usps,
    }
  },
})
