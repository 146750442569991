
  import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from "vue";
  import gsap from 'gsap';

  export default defineComponent({
    name: 'ExplanationAnimation',
    emits: ['updateStep'],
    setup(props, { emit }) {
      const step = ref(1);
      const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.4 });

      const animateSVG = () => {
        const svg = "#how-it-works";
        const parentEl = "#explainer-animation";
        const inputFills = "#fill";
        const button = "#button";
        
        gsap.set(parentEl, { scale: 2, transformOrigin: '60% 100%' });
        gsap.set(svg, { opacity: 0, y: '+=50' });

        gsap.set('#icon--qr-scanner', { transformOrigin: 'center center' });

        gsap.set(inputFills, { scaleX: 0, transformOrigin: 'left center' });
        gsap.set(button, { transformOrigin: 'center' });
        
        gsap.set("#phone-back", { opacity: 0 });

        
        // keyframe 1
        // fade in SVG
        tl.to(svg, {opacity: 1, y: '-=50', duration: 1});

        // keyframe 2
        // Fill in fields
        tl.to(inputFills, { scaleX: 1, duration: 0.5, delay: 1, stagger: -0.3 });
        tl.to(button, { scale: 0.8, duration: 0.1, delay: 0.5 });
        tl.to(button, { scale: 1, duration: 0.1, onComplete: emit, onCompleteParams: ['updateStep', 2] });

        // keyframe 3
        // Turn card content
        tl.to("#phone-front", { opacity: 0, duration: 0.5 });
        tl.to("#phone-back", { opacity: 1, duration: 0.5, delay: 0.5 });

        // keyframe 4
        // Fade in hand + fade out border on phone
        tl.to("#phone", { stroke: 'rgba(0,0,0,0)', duration: 0.3, delay: 1 });
        tl.to("#phone-hand", { opacity: 1, duration: 0.3 }, '<0%');

        // keyframe 5
        // Scale both hands into view
        tl.to(parentEl, { scale: 1, duration: 1 });

        // keyframe 6
        // Move hands together
        
        tl.to("#hand-1", { y: "-=40%", x: "+=20", duration: 1 });
        tl.to("#hand-2", { y: "+=40%", x: "-=20", duration: 1 }, '<0%');

        // keyframe 7
        // Scan QR code
        tl.to("#icon--qr-scanner", { scale: 0.9, duration: 0.2 });
        tl.to("#icon--qr-scanner", { scale: 1, duration: 0.2 });
        tl.to("#icon--qr-scanner", { scale: 0.9, duration: 0.2 });
        tl.to("#icon--qr-scanner", { scale: 1, duration: 0.2, onComplete: emit, onCompleteParams: ['updateStep', 3] });

        // keyframe 8
        // Move hands back
        tl.to("#hand-1", { y: "+=40%", x: "-=20", duration: 1 });
        tl.to("#hand-2", { y: "-=40%", x: "+=20", duration: 1 }, '<0%');

        // keyframe 9
        // Show SCAN COMPLETE state
        tl.to("#icon--qr-scanner", { opacity: 0, duration: 0.3 });
        tl.to("#icon--qr-code", { opacity: 0, duration: 0.3 }, '<0%');
        tl.to("#icon--scan-done", { opacity: 1, duration: 0.3 }, '<0%');
        tl.to("#icon--scan-done", { opacity: 1, duration: 0.3 }, '<0%');

        // keyframe 10
        // Move hands out of view
        tl.to(svg, { 
          opacity: 0, 
          duration: 1, 
          delay: 0.5, 
          onComplete: emit, 
          onCompleteParams: ['updateStep', 1] 
        });

      }

      onMounted(() => {
        animateSVG();
      })

      onBeforeUnmount(() => {
        tl.kill();
      })

      return {
        step
      }
    }
  })
