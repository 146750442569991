
  import { defineComponent, ref, computed } from 'vue';
  import ExplanationAnimation from './ExplanationAnimation.vue'

  export default defineComponent({
    name: 'Slider',
    components: {
      ExplanationAnimation
    },
    setup() {
      const stepCounter = ref(1);
      const stepText = computed(() => {
        switch (stepCounter.value) {
          case(1): 
            return '1/3: Create a card';
          case(2): 
            return '2/3: Scan the QR code';
          case(3): 
            return '3/3: Done! Details shared';
          default: 
            return '';
        }
      })

      return {
        stepCounter,
        stepText,
      }
    }
  })
