
import { computed, ref, defineComponent, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { getDatabase, ref as FBref, onValue } from 'firebase/database';
import VCard from 'vcard-creator'

import CardRead from '@/components/Card/CardRead.vue'
import Loader from '@/components/Loader.vue'

export default defineComponent({
  name: 'Card',
  components: {
    CardRead,
    Loader,
  },
  setup() { 
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const db = getDatabase();

    const cardRef = FBref(db, `cards/${route.query.card}`);
    const cardData = ref();
    const downloadUrl = ref();

    const setDownloadUrl = () => {
      const payload = cardData.value;
      const myVCard:VCard = new VCard()
      const lastname = payload.lastName, 
            firstname = payload.firstName, 
            additional = undefined, 
            prefix = undefined, 
            suffix = undefined,
            email = payload.email || '', 
            phoneNr = payload.phone || '', 
            company = payload.company || '', 
            website = payload.website || '';
            // address = payload.address?.value || '';

      if (lastname || firstname) 
        myVCard.addName(lastname, firstname, additional, prefix, suffix)
      if (company) 
        myVCard.addCompany(company)
      if (email) 
        myVCard.addEmail(email)
      if (phoneNr) 
        myVCard.addPhoneNumber(phoneNr, 'PREF;CELL')
      if (website)
        myVCard.addURL(website)

      // if (address) myVCard.addAddress('name'image.png, 'extended', 'street', 'city', 'region', 'zip', 'country')
      // if (jobTitle) myVCard.addJobtitle(jobTitle)
      // if (role) myVCard.addRole(role)

      const VCardString = myVCard.toString();
      const file = new File([VCardString], `${firstname}.vcf`, {
        type: 'text/directory',
      })

      const url = URL.createObjectURL(file)

      // link.href = url
      // link.download = file.name

      // console.log('url: ', url)

      downloadUrl.value = url;

      // console.log(myVCard.toString())
      // console.log(file)
      //   qrCodeString.value = myVCard.toString();
    }

    watch(cardData, async () => {
      setDownloadUrl();
    })

    onBeforeMount(async () => {
      try {
        await onValue(cardRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            cardData.value = data.fields;
          } else {
            console.error('No card data found')
            router.push({ path: '/not-found' })
          }
          // console.log('snapshot CARD ID data: ', data)
        });
      } catch (err: any) {
        // commit('SET_CARD_LOADING_STATE', false);
        console.error(err) 
      }
    });
    
    return {
      router,
      cardData,
      downloadUrl,
      isUserLoggedIn: computed(() => store.getters.isUserLoggedIn)
    }
  }
})
